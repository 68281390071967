import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { AuthContext } from '../contexts/AuthContext';
import '../styles/AudioGuidePage.css';

const audioGuideData = [
    { title: "Intro", file: "00.intro.mp3" },
    { title: "1: Christian IV's Winter Room", file: "01.mp3" },
    { title: "2: Christian IV's Writing Room", file: "02.mp3" },
    { title: "3: Christian IV's Bedroom", file: "03.mp3" },
    { title: "3A: Christian IV's Toilet", file: "03A.mp3" },
    { title: "4: The Dark Room", file: "04.mp3" },
    { title: "4A: The Garden Room", file: "04a.mp3" },
    { title: "5: The Marble Room", file: "05.mp3" },
    { title: "6: Christian V's Hall", file: "06.mp3" },
    { title: "7: The Stone Corridor", file: "07.mp3" },
    { title: "7A: The stairway to the Stair Turret", file: "07A.mp3" },
    { title: "7B: The Stair Turret", file: "07B.mp3" },
    { title: "8: Frederik IV's Corridor", file: "08.mp3" },
    { title: "9: The Princess' Chamber", file: "09.mp3" },
    { title: "10: Frederik IV's Hall", file: "10.mp3" },
    { title: "11: Frederik IV's Cabinet", file: "11.mp3" },
    { title: "12: Christian VI's Room", file: "12.mp3" },
    { title: "13: The Rose", file: "13.mp3" },
    { title: "13A: The Mirror Cabinet", file: "13A.mp3" },
    { title: "14: Frederik V's Cabinet", file: "14.mp3" },
    { title: "15: Christian VII's Room", file: "15.mp3" },
    { title: "16: Frederik VI's Room", file: "16.mp3" },
    { title: "17: Christian VIII's Room", file: "17.mp3" },
    { title: "18: Frederik VII's Room", file: "18.mp3" },
    { title: "19: The Corridor", file: "19.mp3" },
    { title: "20: The Bronze Room", file: "20.mp3" },
    { title: "21: The Knights' Hall", file: "21.mp3" },
    { title: "22: The Glass Cabinet", file: "22.mp3" },
    { title: "23: The Porcelain Cabinet (closed)", file: "23.mp3" },
    { title: "24: The Regalia Room", file: "24.mp3" },
    { title: "A: Entry to the Treasury", file: "25.mp3" },
    { title: "B: Weapons and wine barrels", file: "26.mp3" },
    { title: "C: Ivory and amber", file: "27.mp3" },
    { title: "D: The Rosenborg Wine", file: "28.mp3" },
    { title: "E: The Green Cabinet", file: "29.mp3" },
    { title: "F: Ole Rømer's Room", file: "30.mp3" },
    { title: "G: The Treasury", file: "31.mp3" },
    { title: "H: The Treasury", file: "32.mp3" },
    { title: "I: The Treasury", file: "33.mp3" }
];

const PRESET_SPEEDS = [0.25, 0.5, 1.0, 1.30, 1.5, 2.0];

const AudioGuidePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { currentUser, loading } = useContext(AuthContext);
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playbackRate, setPlaybackRate] = useState(1.0);
    const [completedTracks, setCompletedTracks] = useState(new Set());
    const audioRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const progressAnimationRef = useRef(null);
    const lastUpdateTimeRef = useRef(0);

    useEffect(() => {
        if (loading) return;

        // Auth check disabled for now, but structure kept for future use
        // if (!currentUser || currentUser.isAnonymous) {
        //     navigate('/', { state: { from: location } });
        //     return;
        // }

        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        };
    }, [currentUser, loading, navigate, location]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.playbackRate = playbackRate;
        }
    }, [currentTrackIndex, playbackRate]);

    const handlePlay = () => {
        if (audioRef.current) {
            audioRef.current.play();
            setIsPlaying(true);
        }
    };

    const handlePause = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handleStop = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    };

    const handleNext = () => {
        if (currentTrackIndex < audioGuideData.length - 1) {
            setCurrentTrackIndex(prev => prev + 1);
            setTimeout(() => {
                if (audioRef.current) {
                    audioRef.current.play();
                    setIsPlaying(true);
                }
            }, 50);
        }
    };

    const handlePrevious = () => {
        if (currentTrackIndex > 0) {
            setCurrentTrackIndex(prev => prev - 1);
            setTimeout(() => {
                if (audioRef.current) {
                    audioRef.current.play();
                    setIsPlaying(true);
                }
            }, 50);
        }
    };

    const handleSpeedChange = (event) => {
        const newSpeed = Math.round(parseFloat(event.target.value) * 10) / 10;
        setPlaybackRate(newSpeed);
        if (audioRef.current) {
            audioRef.current.playbackRate = newSpeed;
        }
    };

    const handlePresetSpeed = (speed) => {
        const newSpeed = Math.round(speed * 10) / 10;
        setPlaybackRate(newSpeed);
        if (audioRef.current) {
            audioRef.current.playbackRate = newSpeed;
        }
    };

    const handleSpeedAdjust = (increment) => {
        const newSpeed = Math.min(Math.max(playbackRate + increment, 0.25), 2.0);
        setPlaybackRate(newSpeed);
        if (audioRef.current) {
            audioRef.current.playbackRate = newSpeed;
        }
    };

    const handleTrackSelect = (index) => {
        setCurrentTrackIndex(index);
        setTimeout(() => {
            if (audioRef.current) {
                audioRef.current.play();
                setIsPlaying(true);
            }
        }, 50);
    };

    const handleTrackComplete = () => {
        setCompletedTracks(prev => new Set([...prev, currentTrackIndex]));
        handleNext();
    };

    const formatSpeed = (speed) => {
        return speed.toFixed(1) + 'x';
    };

    // Smooth progress update using requestAnimationFrame
    useEffect(() => {
        const updateProgress = () => {
            if (audioRef.current && isPlaying) {
                const now = performance.now();
                // Update every 16ms (approximately 60fps)
                if (now - lastUpdateTimeRef.current >= 16) {
                    setCurrentTime(audioRef.current.currentTime);
                    lastUpdateTimeRef.current = now;
                }
                progressAnimationRef.current = requestAnimationFrame(updateProgress);
            }
        };

        if (isPlaying) {
            progressAnimationRef.current = requestAnimationFrame(updateProgress);
        }

        return () => {
            if (progressAnimationRef.current) {
                cancelAnimationFrame(progressAnimationRef.current);
            }
        };
    }, [isPlaying]);

    // Handle time update less frequently for performance
    const handleTimeUpdate = () => {
        // Only update when not playing (seeking)
        if (!isPlaying && audioRef.current) {
            setCurrentTime(audioRef.current.currentTime);
        }
    };

    const handleDurationChange = () => {
        if (audioRef.current) {
            setDuration(audioRef.current.duration);
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const progressPercentage = duration ? (currentTime / duration) * 100 : 0;

    // Add seek functionality
    const handleSeek = (e) => {
        const progressBar = e.currentTarget;
        const bounds = progressBar.getBoundingClientRect();
        const x = e.clientX - bounds.left;
        const width = bounds.width;
        const percentage = Math.min(Math.max(x / width, 0), 1);
        const newTime = percentage * duration;
        
        if (audioRef.current) {
            audioRef.current.currentTime = newTime;
            setCurrentTime(newTime);
        }
    };

    // Add touch seek functionality for mobile
    const handleTouchSeek = (e) => {
        e.preventDefault(); // Prevent scrolling while seeking
        const progressBar = e.currentTarget;
        const bounds = progressBar.getBoundingClientRect();
        const x = e.touches[0].clientX - bounds.left;
        const width = bounds.width;
        const percentage = Math.min(Math.max(x / width, 0), 1);
        const newTime = percentage * duration;
        
        if (audioRef.current) {
            audioRef.current.currentTime = newTime;
            setCurrentTime(newTime);
        }
    };

    if (loading) {
        return (
            <div className="audio-guide-page">
                <Navbar />
                <main className="audio-guide-content">
                    <div className="loading-container">
                        <l-hatch size="40" stroke="4" speed="4.3" color="black" />
                        <p>Loading...</p>
                    </div>
                </main>
                <Footer />
            </div>
        );
    }

    return (
        <div className="audio-guide-page">
            <Navbar />
            <main className="audio-guide-content">
                <div className="audio-guide-header">
                    <div className="title-section">
                        <h1>Audio Guide</h1>
                        <span className="title-separator">/</span>
                        <h1>Roseborg Castle</h1>
                    </div>
                </div>

                <div className="audio-player">
                    <div className="current-track">
                        <h2>{audioGuideData[currentTrackIndex].title}</h2>
                        <div className="audio-guide-progress">
                            <div 
                                className="audio-guide-progress-bar"
                                onClick={handleSeek}
                                onTouchStart={handleTouchSeek}
                                onTouchMove={handleTouchSeek}
                                role="slider"
                                aria-valuemin={0}
                                aria-valuemax={duration}
                                aria-valuenow={currentTime}
                                tabIndex={0}
                            >
                                <div 
                                    className="audio-guide-progress-fill"
                                    style={{ 
                                        transform: `translateX(${progressPercentage - 100}%)`,
                                        transition: isPlaying ? 'transform 0.1s linear' : 'none'
                                    }}
                                ></div>
                                <div 
                                    className="audio-guide-progress-handle"
                                    style={{ 
                                        left: `${progressPercentage}%`,
                                        transition: isPlaying ? 'left 0.1s linear' : 'none'
                                    }}
                                ></div>
                            </div>
                            <div className="audio-guide-time">
                                <span>{formatTime(currentTime)}</span>
                                <span>{formatTime(duration)}</span>
                            </div>
                        </div>
                        <audio
                            ref={audioRef}
                            src={`/audioguide/rosenborg/${audioGuideData[currentTrackIndex].file}`}
                            onEnded={handleTrackComplete}
                            onPlay={() => setIsPlaying(true)}
                            onPause={() => setIsPlaying(false)}
                            onTimeUpdate={handleTimeUpdate}
                            onDurationChange={handleDurationChange}
                        />
                    </div>

                    <div className="controls">
                        <button onClick={handlePrevious} disabled={currentTrackIndex === 0}>
                            <i className="fas fa-step-backward"></i>
                        </button>
                        {!isPlaying ? (
                            <button onClick={handlePlay}>
                                <i className="fas fa-play"></i>
                            </button>
                        ) : (
                            <button onClick={handlePause}>
                                <i className="fas fa-pause"></i>
                            </button>
                        )}
                        <button onClick={handleStop}>
                            <i className="fas fa-stop"></i>
                        </button>
                        <button onClick={handleNext} disabled={currentTrackIndex === audioGuideData.length - 1}>
                            <i className="fas fa-step-forward"></i>
                        </button>
                    </div>

                    <div className="speed-control">
                        <div className="preset-speeds">
                            {PRESET_SPEEDS.map((speed) => (
                                <button
                                    key={speed}
                                    className={`preset-speed ${Math.abs(playbackRate - speed) < 0.01 ? 'active' : ''}`}
                                    onClick={() => handlePresetSpeed(speed)}
                                >
                                    {speed.toFixed(2)}x
                                </button>
                            ))}
                        </div>
                        <div className="current-speed">{playbackRate.toFixed(2)}x</div>
                        <div className="slider-container">
                            <div className="slider-fill"></div>
                            <input
                                type="range"
                                min="0.25"
                                max="2.0"
                                step="0.1"
                                value={playbackRate}
                                onChange={handleSpeedChange}
                                className="speed-slider"
                            />
                        </div>
                    </div>
                </div>

                <div className="track-list">
                    {audioGuideData.map((track, index) => (
                        <div
                            key={index}
                            className={`track-item ${currentTrackIndex === index ? 'active' : ''} 
                                      ${currentTrackIndex === index && isPlaying ? 'playing' : ''} 
                                      ${completedTracks.has(index) ? 'completed' : ''}`}
                            onClick={() => handleTrackSelect(index)}
                        >
                            <span className="track-number">{(index + 1).toString().padStart(2, '0')}</span>
                            <span className="track-title">{track.title}</span>
                            {currentTrackIndex === index && isPlaying && (
                                <div className="playing-indicator">
                                    <div className="playing-animation">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            )}
                            {completedTracks.has(index) && !isPlaying && (
                                <i className="fas fa-check completed-indicator"></i>
                            )}
                        </div>
                    ))}
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default AudioGuidePage; 