import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { AuthContext } from '../contexts/AuthContext';
import '../styles/AudioGuidePage_v2.css';

// Helper function to get image count for a track
const getTrackImages = (trackNumber) => {
    const paddedNumber = trackNumber.toString().padStart(3, '0');
    const images = [];
    let imageIndex = 1;
    
    // We'll hardcode the check for now - you can adjust based on your actual image count
    while (imageIndex <= 2) { // Most locations have 1-2 images
        const imagePath = `/audioguide/helsingor/pics/${paddedNumber}-${imageIndex}.jpeg`;
        images.push(imagePath);
        imageIndex++;
    }
    
    return images;
};

const audioGuideData = [
    { 
        titleEn: "Helsingør Station and Station Place",
        titleRu: "Вокзал Хельсингёр и Площадь станции",
        file: "001.mp3" 
    },
    { 
        titleEn: "Svea Column: A thank you to Sweden for saving Danish Jews",
        titleRu: "Колонна Свеи: Благодарность Швеции за спасение датских евреев",
        file: "002.mp3" 
    },
    { 
        titleEn: "Strandgade/Beach Street",
        titleRu: "Страндгаде/Пляжная улица",
        file: "003.mp3" 
    },
    { 
        titleEn: "Gammel Færgestræde/Old Ferry Street 1",
        titleRu: "Гаммель Фэргестреде/Старая фери-улица 1",
        file: "004.mp3" 
    },
    { 
        titleEn: "Gammel Færgestræde/Old Ferry Street 2",
        titleRu: "Гаммель Фэргестреде/Старая фери-улица 2",
        file: "005.mp3" 
    },
    { 
        titleEn: "Gammel Færgestræde/Old Ferry Street 3",
        titleRu: "Гаммель Фэргестреде/Старая фери-улица 3",
        file: "006.mp3" 
    },
    { 
        titleEn: "Helsingør harbor promenade",
        titleRu: "Прогулочная набережная Хельсингёрского порта",
        file: "007.mp3" 
    },
    { 
        titleEn: "The Culture Yard",
        titleRu: "Культурный двор",
        file: "008.mp3" 
    },
    { 
        titleEn: "The Culture Yard, Up Close",
        titleRu: "Культурный двор, крупным планом",
        file: "009.mp3" 
    },
    { 
        titleEn: "Walking towards the Han Statue - The Male Little Mermaid",
        titleRu: "Прогулка к статуе Хана - Мужская Русалка",
        file: "010.mp3" 
    },
    { 
        titleEn: "Viewing the Han Statue - The Male Little Mermaid",
        titleRu: "Просмотр статуи Хана - Мужская Русалка",
        file: "011.mp3" 
    },
    { 
        titleEn: "Walking past the Maritime Museum - can you see it?",
        titleRu: "Прогулка мимо Морского музея - видите его?",
        file: "012.mp3" 
    },
    { 
        titleEn: "Walking past the Maritime Museum",
        titleRu: "Прогулка мимо Морского музея",
        file: "013.mp3" 
    },
    { 
        titleEn: "Entrance to Kronborg Castle",
        titleRu: "Вход в Кронборгский замок",
        file: "014.mp3" 
    },
    { 
        titleEn: "Walking along the castle moat",
        titleRu: "Прогулка вдоль замкового рва",
        file: "015.mp3" 
    },
    { 
        titleEn: "Decision time: Which Ramparts?",
        titleRu: "Время решений: Ка��ие бастионы?",
        file: "016.mp3" 
    },
    { 
        titleEn: "Kronborg Castle Ramparts 1",
        titleRu: "Бастионы Кронборгского замка 1",
        file: "017.mp3" 
    },
    { 
        titleEn: "Kronborg Castle Ramparts 2",
        titleRu: "Бастионы Кронборгского замка 2",
        file: "018.mp3" 
    },
    { 
        titleEn: "Decision Time: Going into the Castle?",
        titleRu: "Время решений: Войти в замок?",
        file: "019.mp3" 
    },
    { 
        titleEn: "Leaving the castle, heading towards the shipyards",
        titleRu: "Выход из замка, направление к верфям",
        file: "020.mp3" 
    },
    { 
        titleEn: "Approaching the Shipyards",
        titleRu: "Подход к верфям",
        file: "021.mp3" 
    },
    { 
        titleEn: "Down the alleyway to the Shipyard Museums",
        titleRu: "Спуск по переулку к музеям верфей",
        file: "022.mp3" 
    },
    { 
        titleEn: "Red Square and the Shipyard Museums",
        titleRu: "Красная площадь и музеи верфей",
        file: "023.mp3" 
    },
    { 
        titleEn: "Shipyard Workers iron statues",
        titleRu: "Стальные статуи работников верфи",
        file: "024.mp3" 
    },
    { 
        titleEn: "Havnegade/Harbor Street",
        titleRu: "Хавнегаде/Улица порта",
        file: "025.mp3" 
    },
    { 
        titleEn: "Entering the medieval town",
        titleRu: "Вход в средневековый город",
        file: "026.mp3" 
    },
    { 
        titleEn: "Central point medieval Helsingør",
        titleRu: "Центральная точка средневекового Хельсингёра",
        file: "027.mp3" 
    },
    { 
        titleEn: "Saint Olaf's Church",
        titleRu: "Церковь Святого Олафа",
        file: "028.mp3" 
    },
    { 
        titleEn: "The \"Sound Dues\" mural",
        titleRu: "Фреска \"Звуковые пошлины\"",
        file: "029.mp3" 
    },
    { 
        titleEn: "Towards Axeltorv and the statue of Erik of Pomerania",
        titleRu: "В направлении Аксельторва и статуи Эрика Померанца",
        file: "030.mp3" 
    },
    { 
        titleEn: "Sudergade/Tailor Street - Walking towards Axeltorv",
        titleRu: "Судргаде/Портновская улица - Прогулка к Аксельторву",
        file: "031.mp3" 
    },
    { 
        titleEn: "Axeltorv - Cafés and Dive Bars",
        titleRu: "Аксельторв - Кафе и дайв-бары",
        file: "032.mp3" 
    },
    { 
        titleEn: "Bjergegade/Mountain Street and the \"Helsingør Sewing Club\"",
        titleRu: "Бьержегаде/Горная улица и \"Хельсингёрский швейный клуб\"",
        file: "033.mp3" 
    },
    { 
        titleEn: "Stengade/Stone Street",
        titleRu: "Стенгаде/Каменная улица",
        file: "034.mp3" 
    },
    { 
        titleEn: "Brøstræde/Bridge Street",
        titleRu: "Брёстреде/Мостовая улица",
        file: "035.mp3" 
    },
    { 
        titleEn: "Brostræde Ice Cream Shop/\"Brostræde Is\"",
        titleRu: "Магазин мороженого Brostræde Ice Cream/\"Брёстреде Ас\"",
        file: "036.mp3" 
    }
].map((track, index) => ({
    ...track,
    images: getTrackImages(index + 1)
}));

const PRESET_SPEEDS = [0.25, 0.5, 1.0, 1.30, 1.5, 2.0];

const AudioGuidePage_v2 = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { currentUser, loading } = useContext(AuthContext);
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playbackRate, setPlaybackRate] = useState(1.0);
    const [completedTracks, setCompletedTracks] = useState(new Set());
    const audioRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const progressAnimationRef = useRef(null);
    const lastUpdateTimeRef = useRef(0);
    const [language, setLanguage] = useState('en');
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isImageFullscreen, setIsImageFullscreen] = useState(false);
    const [imageError, setImageError] = useState({});
    const imageSliderRef = useRef(null);
    const [autoplayEnabled, setAutoplayEnabled] = useState(true);
    const [trackDurations, setTrackDurations] = useState({});
    const [locationLinks, setLocationLinks] = useState({});

    useEffect(() => {
        if (loading) return;

        // Auth check disabled for now, but structure kept for future use
        // if (!currentUser || currentUser.isAnonymous) {
        //     navigate('/', { state: { from: location } });
        //     return;
        // }

        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        };
    }, [currentUser, loading, navigate, location]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.playbackRate = playbackRate;
        }
    }, [currentTrackIndex, playbackRate]);

    const handlePlay = () => {
        if (audioRef.current) {
            audioRef.current.play();
            setIsPlaying(true);
        }
    };

    const handlePause = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handleStop = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    };

    const handleNext = () => {
        if (currentTrackIndex < audioGuideData.length - 1) {
            setCurrentTrackIndex(prev => prev + 1);
            setTimeout(() => {
                if (audioRef.current) {
                    audioRef.current.play();
                    setIsPlaying(true);
                }
            }, 50);
        }
    };

    const handlePrevious = () => {
        if (currentTrackIndex > 0) {
            setCurrentTrackIndex(prev => prev - 1);
            setTimeout(() => {
                if (audioRef.current) {
                    audioRef.current.play();
                    setIsPlaying(true);
                }
            }, 50);
        }
    };

    const handleSpeedChange = (event) => {
        const newSpeed = Math.round(parseFloat(event.target.value) * 10) / 10;
        setPlaybackRate(newSpeed);
        if (audioRef.current) {
            audioRef.current.playbackRate = newSpeed;
        }
    };

    const handlePresetSpeed = (speed) => {
        const newSpeed = Math.round(speed * 10) / 10;
        setPlaybackRate(newSpeed);
        if (audioRef.current) {
            audioRef.current.playbackRate = newSpeed;
        }
    };

    const handleSpeedAdjust = (increment) => {
        const newSpeed = Math.min(Math.max(playbackRate + increment, 0.25), 2.0);
        setPlaybackRate(newSpeed);
        if (audioRef.current) {
            audioRef.current.playbackRate = newSpeed;
        }
    };

    const handleTrackSelect = (index) => {
        setCurrentTrackIndex(index);
        setTimeout(() => {
            if (audioRef.current) {
                audioRef.current.play();
                setIsPlaying(true);
            }
        }, 50);
    };

    const handleTrackComplete = () => {
        setCompletedTracks(prev => new Set([...prev, currentTrackIndex]));
        if (autoplayEnabled) {
            handleNext();
        } else {
            handleStop();
        }
    };

    const formatSpeed = (speed) => {
        return speed.toFixed(1) + 'x';
    };

    // Smooth progress update using requestAnimationFrame
    useEffect(() => {
        const updateProgress = () => {
            if (audioRef.current && isPlaying) {
                const now = performance.now();
                // Update every 16ms (approximately 60fps)
                if (now - lastUpdateTimeRef.current >= 16) {
                    setCurrentTime(audioRef.current.currentTime);
                    lastUpdateTimeRef.current = now;
                }
                progressAnimationRef.current = requestAnimationFrame(updateProgress);
            }
        };

        if (isPlaying) {
            progressAnimationRef.current = requestAnimationFrame(updateProgress);
        }

        return () => {
            if (progressAnimationRef.current) {
                cancelAnimationFrame(progressAnimationRef.current);
            }
        };
    }, [isPlaying]);

    // Handle time update less frequently for performance
    const handleTimeUpdate = () => {
        // Only update when not playing (seeking)
        if (!isPlaying && audioRef.current) {
            setCurrentTime(audioRef.current.currentTime);
        }
    };

    const handleDurationChange = () => {
        if (audioRef.current) {
            setDuration(audioRef.current.duration);
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const progressPercentage = duration ? (currentTime / duration) * 100 : 0;

    // Add seek functionality
    const handleSeek = (e) => {
        const progressBar = e.currentTarget;
        const bounds = progressBar.getBoundingClientRect();
        const x = e.clientX - bounds.left;
        const width = bounds.width;
        const percentage = Math.min(Math.max(x / width, 0), 1);
        const newTime = percentage * duration;
        
        if (audioRef.current) {
            audioRef.current.currentTime = newTime;
            setCurrentTime(newTime);
        }
    };

    // Add touch seek functionality for mobile
    const handleTouchSeek = (e) => {
        e.preventDefault(); // Prevent scrolling while seeking
        const progressBar = e.currentTarget;
        const bounds = progressBar.getBoundingClientRect();
        const x = e.touches[0].clientX - bounds.left;
        const width = bounds.width;
        const percentage = Math.min(Math.max(x / width, 0), 1);
        const newTime = percentage * duration;
        
        if (audioRef.current) {
            audioRef.current.currentTime = newTime;
            setCurrentTime(newTime);
        }
    };

    // Function to handle image navigation
    const navigateImage = (direction) => {
        const validImages = audioGuideData[currentTrackIndex].images.filter(
            (_, index) => !imageError[`${currentTrackIndex}-${index}`]
        );
        if (validImages.length <= 1) return;

        setCurrentImageIndex(prevIndex => {
            const newIndex = prevIndex + direction;
            if (newIndex >= validImages.length) return 0;
            if (newIndex < 0) return validImages.length - 1;
            return newIndex;
        });
    };

    // Handle track change
    useEffect(() => {
        setCurrentImageIndex(0);
        setImageError({});
    }, [currentTrackIndex]);

    // Handle keyboard navigation for images
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (isImageFullscreen) {
                if (e.key === 'ArrowLeft') navigateImage(-1);
                if (e.key === 'ArrowRight') navigateImage(1);
                if (e.key === 'Escape') setIsImageFullscreen(false);
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [isImageFullscreen]);

    // Handle image error
    const handleImageError = (index) => {
        setImageError(prev => ({
            ...prev,
            [`${currentTrackIndex}-${index}`]: true
        }));
    };

    // Filter out invalid images
    const validImages = audioGuideData[currentTrackIndex].images.filter(
        (_, index) => !imageError[`${currentTrackIndex}-${index}`]
    );

    // Function to load track duration
    const loadTrackDuration = (index) => {
        const audio = new Audio(`/audioguide/helsingor/audio/${audioGuideData[index].file}`);
        audio.addEventListener('loadedmetadata', () => {
            setTrackDurations(prev => ({
                ...prev,
                [index]: audio.duration
            }));
        });
    };

    // Load durations for all tracks
    useEffect(() => {
        audioGuideData.forEach((_, index) => {
            loadTrackDuration(index);
        });
    }, []);

    // Load location links from CSV
    useEffect(() => {
        fetch('/audioguide/helsingor/location.csv')
            .then(response => response.text())
            .then(data => {
                const links = {};
                data.split('\n').forEach(line => {
                    if (line) {
                        const [file, link] = line.split(';');
                        links[file] = link;
                    }
                });
                setLocationLinks(links);
            })
            .catch(error => console.error('Error loading location links:', error));
    }, []);

    // Function to handle direction click
    const handleDirectionClick = (e, link) => {
        e.stopPropagation(); // Prevent track selection when clicking direction
        window.open(link, '_blank');
    };

    if (loading) {
        return (
            <div className="audio-guide-page">
                <Navbar />
                <main className="audio-guide-content">
                    <div className="loading-container">
                        <l-hatch size="40" stroke="4" speed="4.3" color="black" />
                        <p>Loading...</p>
                    </div>
                </main>
                <Footer />
            </div>
        );
    }

    return (
        <div className="audio-guide-page">
            <Navbar />
            <main className="audio-guide-content">
                <div className="audio-guide-header">
                    <div className="title-section">
                        <h1>Audio Guide</h1>
                        <h1>Helsingør Walking Tour</h1>
                        <div className="controls-group">
                            <div className="language-toggle">
                                <button 
                                    className={`lang-btn ${language === 'en' ? 'active' : ''}`}
                                    onClick={() => setLanguage('en')}
                                >
                                    EN
                                </button>
                                <button 
                                    className={`lang-btn ${language === 'ru' ? 'active' : ''}`}
                                    onClick={() => setLanguage('ru')}
                                >
                                    RU
                                </button>
                            </div>
                            <span className="controls-separator">|</span>
                            <div className="autoplay-toggle">
                                <button 
                                    className={`autoplay-btn ${autoplayEnabled ? 'active' : ''}`}
                                    onClick={() => setAutoplayEnabled(!autoplayEnabled)}
                                    title={autoplayEnabled ? "Autoplay enabled" : "Autoplay disabled"}
                                >
                                    {autoplayEnabled ? (
                                        <>
                                            <i className="fas fa-forward"></i>
                                            <span>Auto</span>
                                        </>
                                    ) : (
                                        <>
                                            <i className="fas fa-stop"></i>
                                            <span>Manual</span>
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Image Slider */}
                {validImages.length > 0 && (
                    <div className={`image-slider ${isImageFullscreen ? 'fullscreen' : ''}`} ref={imageSliderRef}>
                        <div className="image-container" onClick={() => setIsImageFullscreen(!isImageFullscreen)}>
                            <img 
                                src={validImages[currentImageIndex]} 
                                alt={`Location ${currentTrackIndex + 1}`}
                                onError={() => handleImageError(currentImageIndex)}
                            />
                            {isImageFullscreen && (
                                <button 
                                    className="close-fullscreen"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsImageFullscreen(false);
                                    }}
                                >
                                    <i className="fas fa-times"></i>
                                </button>
                            )}
                        </div>
                        {validImages.length > 1 && (
                            <>
                                <button 
                                    className="nav-button prev"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigateImage(-1);
                                    }}
                                >
                                    <i className="fas fa-chevron-left"></i>
                                </button>
                                <button 
                                    className="nav-button next"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigateImage(1);
                                    }}
                                >
                                    <i className="fas fa-chevron-right"></i>
                                </button>
                                <div className="image-dots">
                                    {validImages.map((_, index) => (
                                        <button
                                            key={index}
                                            className={`dot ${index === currentImageIndex ? 'active' : ''}`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setCurrentImageIndex(index);
                                            }}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                )}

                <div className="audio-player">
                    <div className="current-track">
                        <h2>{audioGuideData[currentTrackIndex][language === 'en' ? 'titleEn' : 'titleRu']}</h2>
                        <div className="audio-guide-progress">
                            <div className="time-display">
                                <span>{formatTime(currentTime)}</span>
                            </div>
                            <div 
                                className="audio-guide-progress-bar"
                                onClick={handleSeek}
                                onTouchStart={handleTouchSeek}
                                onTouchMove={handleTouchSeek}
                                role="slider"
                                aria-valuemin={0}
                                aria-valuemax={duration}
                                aria-valuenow={currentTime}
                                tabIndex={0}
                            >
                                <div 
                                    className="audio-guide-progress-fill"
                                    style={{ 
                                        transform: `translateX(${progressPercentage - 100}%)`,
                                        transition: isPlaying ? 'transform 0.1s linear' : 'none'
                                    }}
                                ></div>
                                <div 
                                    className="audio-guide-progress-handle"
                                    style={{ 
                                        left: `${progressPercentage}%`,
                                        transition: isPlaying ? 'left 0.1s linear' : 'none'
                                    }}
                                ></div>
                            </div>
                            <div className="time-display">
                                <span>{formatTime(duration)}</span>
                            </div>
                        </div>

                        <audio
                            ref={audioRef}
                            src={`/audioguide/helsingor/audio/${audioGuideData[currentTrackIndex].file}`}
                            onEnded={handleTrackComplete}
                            onPlay={() => setIsPlaying(true)}
                            onPause={() => setIsPlaying(false)}
                            onTimeUpdate={handleTimeUpdate}
                            onDurationChange={handleDurationChange}
                        />

                        <div className="controls">
                            <button onClick={handlePrevious} disabled={currentTrackIndex === 0}>
                                <i className="fas fa-step-backward"></i>
                            </button>
                            {!isPlaying ? (
                                <button onClick={handlePlay}>
                                    <i className="fas fa-play"></i>
                                </button>
                            ) : (
                                <button onClick={handlePause}>
                                    <i className="fas fa-pause"></i>
                                </button>
                            )}
                            <button onClick={handleStop}>
                                <i className="fas fa-stop"></i>
                            </button>
                            <button onClick={handleNext} disabled={currentTrackIndex === audioGuideData.length - 1}>
                                <i className="fas fa-step-forward"></i>
                            </button>
                        </div>

                        <div className="speed-control">
                            <div className="preset-speeds">
                                {PRESET_SPEEDS.map((speed) => (
                                    <button
                                        key={speed}
                                        className={`preset-speed ${Math.abs(playbackRate - speed) < 0.01 ? 'active' : ''}`}
                                        onClick={() => handlePresetSpeed(speed)}
                                    >
                                        {speed.toFixed(2)}x
                                    </button>
                                ))}
                            </div>
                            <div className="current-speed">{playbackRate.toFixed(2)}x</div>
                            <div className="slider-container">
                                <div className="slider-fill"></div>
                                <input
                                    type="range"
                                    min="0.25"
                                    max="2.0"
                                    step="0.1"
                                    value={playbackRate}
                                    onChange={handleSpeedChange}
                                    className="speed-slider"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="track-list">
                    {audioGuideData.map((track, index) => (
                        <div
                            key={index}
                            className={`track-item ${currentTrackIndex === index ? 'active' : ''} 
                                      ${currentTrackIndex === index && isPlaying ? 'playing' : ''} 
                                      ${completedTracks.has(index) ? 'completed' : ''}`}
                            onClick={() => handleTrackSelect(index)}
                        >
                            <div className="track-info">
                                <span className="track-number">{(index + 1).toString().padStart(2, '0')}</span>
                                <span className="track-title">{track[language === 'en' ? 'titleEn' : 'titleRu']}</span>
                            </div>
                            <div className="track-duration">
                                {trackDurations[index] ? formatTime(trackDurations[index]) : '--:--'}
                            </div>
                            {locationLinks[track.file] && (
                                <button 
                                    className="direction-button"
                                    onClick={(e) => handleDirectionClick(e, locationLinks[track.file])}
                                    title="Get directions"
                                >
                                    <i className="fas fa-directions"></i>
                                </button>
                            )}
                            {currentTrackIndex === index && isPlaying && (
                                <div className="playing-indicator">
                                    <div className="playing-animation">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            )}
                            {completedTracks.has(index) && !isPlaying && (
                                <i className="fas fa-check completed-indicator"></i>
                            )}
                        </div>
                    ))}
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default AudioGuidePage_v2; 