import React, { useEffect, useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../styles/Contact.css';
import { trackEvent } from '../analytics';

function Contact() {
  const [state, handleSubmit] = useForm("xwpkvaqj");
  const location = useLocation();
  const [formData, setFormData] = useState({
    reason: '',
    message: ''
  });

  useEffect(() => {
    // Get URL query parameters
    const params = new URLSearchParams(location.search);
    const reasonParam = params.get('reason');
    const messageParam = params.get('message');

    // Set initial form data based on URL parameters
    setFormData({
      reason: reasonParam === 'demo' ? 'partnership' : reasonParam || '',
      message: messageParam || ''
    });
  }, [location]);

  const handleFormSubmit = (e) => {
    handleSubmit(e);
    const reason = e.target.reason.value;
    trackEvent({
      action: 'submit_contact_form',
      category: 'Contact',
      label: `Reason: ${reason}`,
      value: 1,
    });
  };

  if (state.succeeded) {
    return (
      <div className="contact-page">
        <Navbar />
        <main className="main-content">
          <div className="content-block success-message-container">
            <div className="success-content">
              <i className="fas fa-check-circle success-icon"></i>
              <h1>Thank You!</h1>
              <p>We've received your message and will get back to you shortly.</p>
              <a href="/" className="cta-button">
                Back to Home
              </a>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="contact-page">
      <Navbar />
      <main className="main-content">
        <div className="content-block">
          <h1>Contact Us</h1>
          <p>
            Have questions or need assistance? Reach out to us! We're here to help plan your perfect journey.
          </p>
          
          <div className="contact-info">
            <div className="contact-card">
              <h3>Support</h3>
              <a href="mailto:support@tales.travel">support@tales.travel</a>
              <p>For general inquiries and customer support</p>
            </div>
            <div className="contact-card">
              <h3>Partnerships</h3>
              <a href="mailto:partnerships@tales.travel">partnerships@tales.travel</a>
              <p>For business and collaboration opportunities</p>
            </div>
            <div className="contact-card">
              <h3>Careers</h3>
              <a href="mailto:careers@tales.travel">careers@tales.travel</a>
              <p>For job opportunities and recruitment</p>
            </div>
            <div className="contact-card">
              <h3>Social & PR</h3>
              <a href="mailto:social@tales.travel">social@tales.travel</a>
              <p>For media inquiries and social collaborations</p>
            </div>
          </div>

          <div className="form-divider">
            <span>or send us a message</span>
          </div>

          <form className="contact-form" onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="firstName">First name</label>
              <input 
                type="text" 
                id="firstName" 
                name="firstName" 
                placeholder="Your first name" 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last name</label>
              <input 
                type="text" 
                id="lastName" 
                name="lastName" 
                placeholder="Your last name" 
                required 
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input 
                type="email" 
                id="email" 
                name="email" 
                placeholder="Your email" 
                required 
              />
              <ValidationError 
                prefix="Email" 
                field="email"
                errors={state.errors}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone number</label>
              <input 
                type="tel" 
                id="phone" 
                name="phone" 
                placeholder="Your phone number" 
              />
            </div>
            <div className="form-group">
              <label htmlFor="company">Company name & title</label>
              <input 
                type="text" 
                id="company" 
                name="company" 
                placeholder="Company name (if applicable)" 
              />
            </div>
            <div className="form-group">
              <label htmlFor="reason">Reason for reaching out</label>
              <select 
                id="reason" 
                name="reason" 
                required
                value={formData.reason}
                onChange={(e) => setFormData(prev => ({ ...prev, reason: e.target.value }))}
              >
                <option value="">Select reason</option>
                <option value="help">Need help</option>
                <option value="partnership">Request demo / Partnership</option>
                <option value="feedback">Providing feedback</option>
                <option value="creator">Mindtrip Creator inquiry</option>
                <option value="press">Press inquiry</option>
                <option value="other">General inquiry / other</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea 
                id="message" 
                name="message" 
                placeholder="Tell us more..." 
                required
                rows="5"
                value={formData.message}
                onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
              ></textarea>
              <ValidationError 
                prefix="Message" 
                field="message"
                errors={state.errors}
              />
            </div>
            <button type="submit" className="cta-button" disabled={state.submitting}>
              {state.submitting ? 'Sending...' : 'Send Message'}
            </button>
          </form>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Contact;
  