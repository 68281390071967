import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../styles/AudioGuideFeaturesPage.css';

const AudioGuideFeaturesPage = () => {
    return (
        <div className="audio-features-page">
            <Navbar />
            <main className="main-content">
                <div className="content-grid">
                    {/* Hero Section */}
                    <section className="content-block hero-section">
                        <h1>
                            <span className="highlight">Experience the World Through Your Own Lens</span>
                        </h1>
                        <h2>with Our Custom Audio Guides</h2>
                        <p className="hero-description">
                            At Tales.Travel, we believe that every visitor deserves an engaging, meaningful encounter with art, history, and culture. Our carefully crafted audio guides enrich any space—be it a museum, gallery, cultural landmark, or heritage site—by combining expert insights with intuitive storytelling. Tailored to each listener's interests and language preferences, our guides help you explore at your own pace, absorb context without distraction, and make authentic connections with what you see.
                        </p>
                    </section>

                    {/* Features Section */}
                    <section className="content-block features-section">
                        <h2>Key Features of Our Audio Guides</h2>
                        <div className="features-content">
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <i className="fas fa-user-edit"></i>
                                </div>
                                <h3>Personalized Content</h3>
                                <p>We collaborate closely with cultural institutions and experts to ensure that each narrative is both authoritative and adaptable. Curators, educators, and local historians help shape the content so visitors receive the most relevant and up-to-date information.</p>
                            </div>
                            <div className="feature-item">
                                <div className="feature-icon">
                                    <i className="fas fa-language"></i>
                                </div>
                                <h3>Multilingual Support</h3>
                                <p>Our platform supports a range of languages, allowing you to cater to a global audience. Meet the needs of international visitors or provide deeper context for locals who appreciate nuanced storytelling.</p>
                            </div>
                        </div>
                    </section>

                    {/* B2B Solutions Section */}
                    <section className="content-block solutions-section">
                        <h2>B2B Solutions for Cultural Institutions and Partners</h2>
                        <div className="solutions-content">
                            <div className="solution-item">
                                <h3 className="highlight">Customized Narrative Development</h3>
                                <p>Our team of content specialists works hand-in-hand with your institution's staff to design narratives that support your interpretive goals, highlight signature pieces, and reflect your unique curatorial voice.</p>
                            </div>
                            <div className="solution-item">
                                <h3 className="highlight">Seamless Integration</h3>
                                <p>We make implementation easy. From content creation to technical support, we ensure your audio guide solution aligns with existing visitor workflows, branding, and interpretive strategies.</p>
                            </div>
                        </div>
                    </section>

                    {/* CTA Section */}
                    <section className="content-block cta-section">
                        <h2>Ready to Enhance Your Cultural Experience?</h2>
                        <p>
                            For institutions, tour operators, and event organizers seeking innovative and accessible ways to captivate audiences, our B2B solutions bring your stories to life and meet the evolving demands of modern visitors. Individual culture lovers can download our app to unlock a world of personalized exploration, deepening their appreciation of art and heritage.
                        </p>
                        <div className="cta-buttons">
                            <a href="/contact" className="cta-button primary">Contact Us</a>
                            <a 
                                href="/contact?reason=demo&message=I would like to request a demo of your audio guide solution." 
                                className="cta-button secondary"
                            >
                                Request Demo
                            </a>
                        </div>
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default AudioGuideFeaturesPage; 