import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import '../styles/variables.css'; // Ensure variables are imported first if needed
import '../styles/Navbar.css';
import { AuthContext } from '../contexts/AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import SignIn from '../pages/SignIn';

const Navbar = ({ extraSpacing }) => {
  const [scrolled, setScrolled] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log('User signed out successfully.');
    } catch (error) {
      console.error('Sign-out error:', error);
      alert('Failed to sign out. Please try again.');
    }
  };

  const toggleSignInModal = () => {
    setShowSignInModal((prev) => !prev);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  // Close mobile menu when a link is clicked
  const handleLinkClick = () => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  };

  return (
    <>
      <nav className={`navbar ${scrolled ? 'navbar-scrolled' : ''}`}>
        <div className="navbar-container">
          <div className={`navbar-brand ${extraSpacing ? 'extra-spacing' : ''}`}>
            <Link to="/" className="navbar-logo" onClick={handleLinkClick}>
              <h1>Tales.Travel</h1>
              <p>Crafting unforgettable journeys</p>
            </Link>
          </div>
          <div className={`navbar-links ${mobileMenuOpen ? 'active' : ''}`}>
            <Link to="/" className="nav-link" onClick={handleLinkClick}>
              <span className="nav-link-text">Home</span>
            </Link>
            <Link to="/about" className="nav-link" onClick={handleLinkClick}>
              <span className="nav-link-text">About</span>
            </Link>
            <Link to="/audio-guides" className="nav-link" onClick={handleLinkClick}>
              <span className="nav-link-text">Audio Guides</span>
            </Link>
            <Link to="/contact" className="nav-link" onClick={handleLinkClick}>
              <span className="nav-link-text">Contact</span>
            </Link>
            {currentUser && !currentUser.isAnonymous ? (
              <>
                <Link to="/trips" className="nav-link" onClick={handleLinkClick}>
                  <span className="nav-link-text">My Trips</span>
                </Link>
                <button onClick={handleSignOut} className="nav-link button-link" aria-label="Sign Out">
                  <span className="nav-link-text">Sign Out</span>
                </button>
              </>
            ) : (
              <button onClick={toggleSignInModal} className="nav-link button-link" aria-label="Sign In">
                <span className="nav-link-text">Sign In</span>
              </button>
            )}
          </div>
          <button
            className="mobile-menu-button"
            onClick={toggleMobileMenu}
            aria-label={mobileMenuOpen ? 'Close Menu' : 'Open Menu'}
            aria-expanded={mobileMenuOpen}
          >
            <i className={`fas ${mobileMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
          </button>
        </div>
      </nav>
      {showSignInModal && (
        <div
          className="modal-overlay"
          onClick={toggleSignInModal}
          role="dialog"
          aria-modal="true"
          aria-labelledby="sign-in-modal-title"
        >
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-modal" onClick={toggleSignInModal} aria-label="Close Sign In Modal">
              ×
            </button>
            <h2 id="sign-in-modal-title">Sign In</h2>
            <SignIn onClose={toggleSignInModal} />
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
